import { render, staticRenderFns } from "./banner.vue?vue&type=template&id=5cb7b60a&scoped=true"
import script from "./banner.vue?vue&type=script&lang=js"
export * from "./banner.vue?vue&type=script&lang=js"
import style0 from "./banner.vue?vue&type=style&index=0&id=5cb7b60a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5cb7b60a",
  null
  
)

export default component.exports