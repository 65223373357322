<template>
  <div class="purchase" v-ripple.1000>
    <div class="purchaseTitle">
      <!-- <span class="title">现货销售</span> -->
    </div>
    <div class="purchaseContainer">
      <el-row>
        <el-col :span="4">
          <span tag="span" @click="ResourceDocumentsClick"
            ><img
              src="../../../assets/images/homePage/spotSell.jpg"
              style="cursor: pointer"
              width="100%"
              height="330px"
              alt=""
          /></span>
        </el-col>
        <el-col :span="4" v-for="(item, index) in purchaseDatas" :key="index">
          <div
            v-if="index <= 3"
            class="purchaseOrderItem"
            @click="goProductionDetails(item.hangResourcesId)"
          >
            <div class="newSoptStyle">
              <div style="display: flex;justify-content: center">
                <el-badge value="定向" v-if="item.listingStatus === 1" :key="item.img + index" style="margin:10px auto 0 auto;">
                  <h1 class="title">{{ item.className }}</h1>
                </el-badge>
                <h1 class="title" v-else>{{ item.className }}</h1>
              </div>
              <!-- <div class="info infoQ">
                <span>重量: <i class="garyColor">{{ item.hangWeight }}{{ item.unit }}</i></span>
                <span class="rightTitle">{{ item.specificationsName|keyAttributeTransformate }} <i class="garyColor">{{item.specificationsName|valueAttributeTransformate}}</i></span>
              </div> -->
              <div>
                <p class="priceQ">
                  ￥ <span class="fontStyle">{{ item.price }}</span
                  >/{{ item.unit }} <i></i>
                </p>
                <p class="isNegotiable">
                  {{ item.bargainingStatus == "T" ? "价格可议" : "价格不可议" }}
                </p>
              </div>
              <div class="bottomBox">
                <!-- <p class="openTicket">开票期限：<span >{{ item.openTicketTime | DateFormateDay }}</span></p> -->
                <p class="date">
                  日期：<span>{{ item.hangCreateTime | DateFormateDay }}</span>
                </p>
                <p class="firstNames">交收仓库: {{ item.sname }}</p>
                <p class="localName">
                  <span class="firstName">{{ item.hangEnterpriseName }}</span
                  ><span class="more">></span>
                </p>
              </div>
            </div>
            <!-- <div class="newSoptStyle">
              <div class="topBox">
                <div><img :src="item.img" alt=""  style="height:100px;max-width:120px"></div>
                <div>
                  <p class="isK">{{item.bargainingStatus=='T'?'价格可议':'价格不可议'}}</p>
                  <p class="price"> ￥ <span class="fontStyle">{{ item.price }}</span>/{{ item.unit }}</p>
                </div>
              </div>
              <h1>{{ item.className }}</h1>
              <div class="info">
                <span>重量: <i class="garyColor">{{ item.hangWeight }}{{ item.unit }}</i></span>
                <span class="rightTitle">{{ item.specificationsName|keyAttributeTransformate }} <i class="garyColor">{{item.specificationsName|valueAttributeTransformate}}</i></span>
              </div>
              <div class="bottomBox">
                <p class="date">日期：<span >{{ item.hangCreateTime | DateFormateDay }}</span></p>
                <p class="localName"><span class="firstName">交收地: {{ item.sname }}</span><span class="more">></span></p>
              </div>
            </div> -->
            <!-- <p class="title flexStyle">
              <span class="titleInfo">{{ item.className }}</span>
              <span class="rightTitle">{{ item.specificationsName|firstAttributeTransformate }}</span>
              <img
                v-if="item.bargainingStatus=='T'"
                class="negotiable"
                src="../../../assets/images/keyi.png"
                alt="可议价"
                width="30px"
              />
            </p> -->
            <!-- <p class="flexStyle date">
              <span>{{ item.hangWeight }}{{ item.unit }}</span>
              <span class="redColor">{{ item.price }}元/{{ item.unit }}</span>
            </p>
            <p class="flexStyle date">
              <span class="newDate">交收地:{{ item.sname }}</span>
            </p> -->
            <!-- <div class="flexStyle date">
              <span class="ensconce">{{ item.hangCreateTime | DateFormateDay }}</span>
               <p class="stutas" @click="goProductionDetails(item.hangResourcesId)">
                <span class="statusText">{{ item.hangResourcesStatus | hangStatusFormate }}</span>
              </p>
            </div> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 挂牌/竞价弹窗 -->
    <div id="dialog3">
      <el-dialog :visible.sync="dialogVisible2" width="30%">
        <!-- <div>您好，您即将进行{{ lstName }}市场绑定，请谨慎操作！</div> -->
        <div>您好，您即将进行现货{{ lstName }}模式，请确认！</div>
        <div class="d2_btns">
          <el-button
            @click="lstEnter"
            :disabled="isDisabled"
            type="primary"
            style="margin-left: 15px"
            >确认</el-button
          >
          <el-button @click="cancel3">取消</el-button>
        </div>
      </el-dialog>
    </div>
    <examinationPaper ref="examinationPaper" @enterInto="goPagess" />
  </div>
</template>
<script>
import examinationPaper from "@/components/HomePage/component/children/examinationPaper";
export default {
  components: {
    examinationPaper
  },
  data() {
    return {
      isProductFlag: null, // 商品标识 1 有商品id 2 无商品id
      flagsss: 1,
      carouselCount: 1,
      purchaseDatas: [],
      userMes: "",
      lstName: "挂牌",
      dialogVisible2: false,
      id: null,
      isDisabled: false
    };
  },
  mounted() {
    this.getPurchaseDatas();
    this.userMes = this.$store.state.common.sessionInfo;
  },
  methods: {
    goPagess() {
      if (this.isProductFlag == 1) {
        this.flagsss = 2;
        this.searchFirmId();
        this.lstName = "挂牌";
        var lstFlag = false;
        var arr1 = this.$store.state.search.firmList;
        for (var a = 0; a < arr1.length; a++) {
          if (arr1[a].marketId == 18) {
            lstFlag = true;
          }
        }
        if (lstFlag) {
          this.getIdentificationStatus2(
            "ResourceDocuments/details/" +
              this.id,
            "挂牌"
          );
        } else {
          this.dialogVisible2 = true;
        }
      } else if (this.isProductFlag == 2) {
        this.flagsss = 1;
        this.searchFirmId();
        this.lstName = "挂牌";
        var lstFlag = false;
        var arr1 = this.$store.state.search.firmList;
        for (var a = 0; a < arr1.length; a++) {
          if (arr1[a].marketId == 18) {
            lstFlag = true;
          }
        }
        if (lstFlag) {
          // this.activeIndexs = '2';
          this.getIdentificationStatus2(
            "/ResourceDocuments",
            "挂牌"
          );
        } else {
          // localStorage.setItem("latFLag_w", 0);
          this.dialogVisible2 = true;
        }
      }
    },
    lstEnter() {
      sessionStorage.setItem("marketId_w", 18);
      var that = this;
      this.isDisabled = true;
      protocol.param_dredgeBazaar.param.userId = this.userMes.userId;
      protocol.param_dredgeBazaar.param.marketId = 18;
      protocol.param_dredgeBazaar.param.lnvitationCode = null;
      protocol.param_dredgeBazaar.param.lnvitationCodeMustFillIn = false;
      http.postFrontMarket(protocol.param_dredgeBazaar).then(response => {
        var { message, code, value } = response.data;
        if (Number(code) === 0) {
          setTimeout(() => {
            that.isDisabled = false;
          }, 1500);
          this.searchFirmId();
          this.$EL_MESSAGE("成功");
          this.dialogVisible2 = false;
          if ((this.flagsss = 1)) {
            this.getIdentificationStatus2(
              "/ResourceDocuments",
              "挂牌"
            );
          } else if ((this.flagsss = 2)) {
            this.getIdentificationStatus2(
              "/ResourceDocuments/details/" +
                this.id,
              "挂牌"
            );
          }
          localStorage.setItem("atPresentFirmId", value);
        } else {
          that.isDisabled = false;
          this.searchFirmId();
          this.$EL_MESSAGE(message);
        }
      });
    },
    searchFirmId() {
      protocolFwd.param_firmIdSearch.param.userId = this.userMes.userId;
      http.postFront(protocolFwd.param_firmIdSearch).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          this.$store.commit("setFirmList", value);
          localStorage.setItem("setFirmList_w", JSON.stringify(value));
          if (value.length != 0) {
            this.isAssets = true;
            if (value[0].marketId == 18) {
              this.bidFlagss = 1;
              this.$store.commit("setIsFlagss", 1);
              localStorage.setItem("bidFlagss", 1);
              sessionStorage.setItem("userFirmId", value.firmId);
            } else if (value[0].marketId == 28) {
              this.bidFlagss = 2;
              this.$store.commit("setIsFlagss", 2);
              localStorage.setItem("bidFlagss", 2);
            }
          } else {
            this.$store.commit("setIsFlagss", 0);
            this.isAssets = false;
          }
        }
      });
    },
    // 认证状态查询
    getIdentificationStatus2(url, name) {
      console.log(this.$store.state.search.firmList);
      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          var arr = this.$store.state.search.firmList;
          var firmId = 0;
          for (let i = 0; i < arr.length; i++) {
            if (arr[i].marketId == 18) {
              firmId = arr[i].firmId;
              localStorage.setItem("atPresentFirmId", firmId);
            }
          }
          var items = {
            carousel: 168,
            icon: "det.png",
            iconSelect: "det.png",
            marketId: 28,
            name: name,
            notice: 167,
            url: url
          };
          this.$store.commit("setHeaderText", items);
          localStorage.setItem("setHeaderText", JSON.stringify(items));
          this.$router.push("/moduleXH");
          // const { code, message, value } = response.data;
          // console.log(value, "认证信息");
          // if (Number(code) === 0) {
          //   if (value == null) {
          //     this.$EL_MESSAGE("暂无交易权限，请先企业认证");
          //     this.$router.push("/personalEnterpriseAuth");
          //   } else {
          //     // 认证类型（O:组织认证 P:个人认证）
          //     if (value.auditType == "O") {
          //       if (value.auditStatus == 1) {
          //         var arr = this.$store.state.search.firmList;
          //         var firmId = 0;
          //         for (let i = 0; i < arr.length; i++) {
          //           if (arr[i].marketId == 18) {
          //             firmId = arr[i].firmId;
          //             localStorage.setItem("atPresentFirmId", firmId);
          //           }
          //         }
          //         var items = {
          //           carousel: 168,
          //           icon: "det.png",
          //           iconSelect: "det.png",
          //           marketId: 28,
          //           name: name,
          //           notice: 167,
          //           url: url,
          //         };
          //         this.$store.commit("setHeaderText", items);
          //         localStorage.setItem("setHeaderText", JSON.stringify(items));
          //         this.$router.push("/iframeModule");
          //       } else {
          //       }
          //     } else {
          //       protocol.param_getBaseUserBindByUserId.param.userId = this.userMes.userId;
          //       http
          //         .postFront(protocol.param_getBaseUserBindByUserId)
          //         .then((response) => {
          //           var { message, value, code } = response.data;
          //           if (Number(code) === 0) {
          //             if (value) {
          //               var arr = this.$store.state.search.firmList;
          //               var firmId = 0;
          //               for (let i = 0; i < arr.length; i++) {
          //                 if (arr[i].marketId == 18) {
          //                   firmId = arr[i].firmId;
          //                   localStorage.setItem("atPresentFirmId", firmId);
          //                 }
          //               }
          //               var items = {
          //                 carousel: 168,
          //                 icon: "det.png",
          //                 iconSelect: "det.png",
          //                 marketId: 28,
          //                 name: name,
          //                 notice: 167,
          //                 url: url,
          //               };
          //               this.$store.commit("setHeaderText", items);
          //               localStorage.setItem(
          //                 "setHeaderText",
          //                 JSON.stringify(items)
          //               );
          //               this.$router.push("/iframeModule");
          //             } else {
          //               this.$EL_MESSAGE("暂无交易权限，请先企业认证");
          //               this.$router.push("/personalEnterpriseAuth");
          //             }
          //           }
          //         });

          //       // this.$EL_MESSAGE('暂无交易权限，请先企业认证')
          //       // this.$router.push('/personalEnterpriseAuth')
          //     }
          //   }
          // } else {
          //   this.$EL_MESSAGE(message);
          // }
        });
    },
    cancel3() {
      this.$router.push("/HomePage1");
      this.dialogVisible2 = false;
      // this.activeIndexs = 0
    },
    // 更多
    ResourceDocumentsClick() {
      if (!window.g.LST_FLAG) {
        this.$EL_MESSAGE("暂未开放");
        return;
      }
      // 查询是否确认协议
      http.postFront(protocol.param_agreementEnter).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入挂牌/竞价市场
            this.getIdentificationStatus2(
              "/ResourceDocuments",
              "挂牌"
            );
          } else {
            // 未通过,进入新手引导
            this.$router.push("/guide");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 进入首页挂牌竞价商品详情(新手引导)
    goProductionDetails(id) {
      if (!window.g.LST_FLAG) {
        this.$EL_MESSAGE("暂未开放");
        return;
      }
      this.isProductFlag = 1;
      this.id = id;
      var arr = JSON.parse(localStorage.getItem("marketList_w"));
      // 查询是否确认协议
      http.postFront(protocol.param_agreementEnter).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入挂牌竞价市场
            this.getIdentificationStatus2(
              "/ResourceDocuments/details/" + id,
              "挂牌"
            );
          } else {
            // 未通过,进入新手引导
            this.$router.push("/guide");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getPurchaseDatas() {
      protocolFwd.param_queryFirmOrderBeforeConfirmA.param = {
        page: 0,
        size: 10,
        sort: [{ property: "hangCreateTime", direction: "DESC" }],
        hangResourceType: ["X"],
        auditStatus: ["T"],
        hangResourcesStatus: ["O", "B", "A"],
        sourceId: "W",
        marketId: 18,
      };
      http
        .postFront(protocolFwd.param_queryFirmOrderBeforeConfirmA)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (value.content.length > 0) {
              for (let i = 0; i < value.content.length; i++) {
                if (Number(value.content[i].unsettledWeight) <= 0) {
                  value.content.splice(i, 1);
                }
              }
            }
            this.purchaseDatas = value.content;
            if (value.content.length > 9) {
              this.carouselCount = 2;
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.purchase {
  /* padding: 10px; */
  height: 330px;
  /* background-color: #ffffff; */
}
.purchase:hover {
  box-shadow: 0px 0px 3px 3px #ccc;
  transition: box-shadow 0.5s;
}
.purchaseTitle {
  overflow: hidden;
}
.purchaseTitle .title {
  font-weight: 700;
  font-size: 22px;
  color: #468be5;
}
.purchaseTitle .desc {
  margin-left: 5px;
  color: #9999a2;
  font-size: 12px;
}
.moreRight {
  float: right;
  line-height: 22px;
  color: #666666;
  cursor: pointer;
}
.purchaseOrderItem {
  position: relative;
  padding: 20px;
  height: 330px;
  line-height: 28px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-right-color: #ddd;
  box-sizing: border-box;
}

.purchaseOrderItem:hover {
  border: 1px solid #468be5;
  /* box-shadow: 0px 0px 3px 3px #ccc;
  transition: box-shadow 0.5s; */
}

.purchaseOrderItem p.date,
.purchaseOrderItem p.company {
  font-size: 12px;
  /* color: #9999a2; */
  overflow: hidden;
}
.purchaseOrderItem .flexStyle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: #9999a2;
}
.purchaseOrderItem p.flexStyle .newDate {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.purchaseOrderItem p.flexStyle .ensconce {
  overflow: hidden;
  display: inline-block;
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.purchaseOrderItem p.flexStyle .rightEnsconce {
  overflow: hidden;
  display: inline-block;
  width: 20%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.purchaseOrderItem p.title {
  /* width: 80%; */
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
  color: #666666;
  position: relative;
}
.purchaseOrderItem p.flexStyle .redColor {
  color: red;
  font-weight: 600;
}
.purchaseOrderItem .flexStyle .titleInfo {
  width: 30%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.purchaseOrderItem .flexStyle .rightTitle {
  width: calc(70% - 30px);
  float: left;
  /* display: inline-block; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.purchaseOrderItem .title .titleInfo {
  width: 30%;
  float: left;
  /* display: inline-block; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}
.purchaseOrderItem .title .isGrade {
  text-indent: 40px;
  width: 54%;
}
.purchaseOrderItem .title .rightTitle {
  width: calc(70% - 30px);
  float: left;
  /* display: inline-block; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.purchaseOrderItem .title .isRightGrade {
  width: calc(60% - 30px);
}
.purchaseOrderItem .title .titleInfo .grades {
  position: absolute;
  left: 0;
  top: -5px;
}
.purchaseOrderItem .title .negotiable {
  float: right;
  width: 30px;
  margin-top: -5px;
  /* position:absolute;
  right: 0px;
  top:-5px; */
}
.purchaseOrderItem p.company * {
  font-size: 12px;
}
.purchaseOrderItem p.company .location {
  float: right;
}
.purchaseOrderItem p.company .enterpriseName {
  display: inline-block;
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.purchaseOrderItem p.company .benterpriseName {
  display: inline-block;
  max-width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.purchaseOrderItem p.company .orderPrice {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.purchaseOrderItem .status {
  position: absolute;
  bottom: 5px;
  right: 10px;
  line-height: 20px;
}
.purchaseOrderItem .status .leftAngle {
  float: left;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #468be5 transparent transparent;
}
.purchaseOrderItem .statusText {
  float: left;
  display: block;
  padding: 0 8px;
  line-height: 20px;
  font-size: 12px;
  min-width: 50px;
  text-align: center;
  color: #ffffff;
  background-color: #468be5;
  cursor: pointer;
}
.d2_btns {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
