<template>
  <el-dialog
    title="计算器"
    v-dialogDrag
    :visible.sync="dialogVisible"
    width="400px"
    :before-close="handleClose">
    <div id="calculator">
  <!--显示框-->
  <input-box v-bind:input-show="inputShow">
  </input-box>
  <btn-list>
    <div @click="clearValue()" class=" btn-30 btn-radius color-red clear-marginleft">C</div>
    <div class=" btn-30 btn-radius color-blue">+/-</div>
    <div @click="inputValue('%')" class=" btn-30 btn-radius color-blue">%</div>
    <div @click="backValue()" class=" btn-70 btn-radius color-red font-14">←</div>
    <div @click="inputValue('7')" class=" btn-30 btn-radius clear-marginleft">7</div>
    <div @click="inputValue('8')" class=" btn-30 btn-radius">8</div>
    <div @click="inputValue('9')" class=" btn-30 btn-radius">9</div>
    <div @click="squareValue()" class=" btn-30 btn-radius color-blue font-14">×²</div>
    <div @click="radicalValue()" class=" btn-30 btn-radius color-blue font-12">√</div>
    <div @click="inputValue('4')" class=" btn-30 btn-radius clear-marginleft">4</div>
    <div @click="inputValue('5')" class=" btn-30 btn-radius">5</div>
    <div @click="inputValue('6')" class=" btn-30 btn-radius">6</div>
    <div @click="inputValue('×')" class=" btn-30 btn-radius color-blue font-14">×</div>
    <div @click="inputValue('÷')" class=" btn-30 btn-radius color-blue font-12">÷</div>
    <div @click="inputValue('1')" class=" btn-30 btn-radius clear-marginleft">1</div>
    <div @click="inputValue('2')" class=" btn-30 btn-radius">2</div>
    <div @click="inputValue('3')" class=" btn-30 btn-radius">3</div>
    <div @click="inputValue('+')" class=" btn-30 btn-radius color-blue font-14">+</div>
    <div @click="inputValue('-')" class=" btn-30 btn-radius color-blue font-14">-</div>
    <div @click="inputValue('0')" class=" btn-70 btn-radius clear-marginleft">0</div>
    <div @click="inputValue('.')" class=" btn-30 btn-radius">.</div>
    <div @click="calValue()" class=" btn-70 btn-radius color-red font-14">=</div>
  </btn-list>
</div>
  </el-dialog>
</template>
<script>
export default {
  data() {
      return {
        dialogVisible: false,
        inputShow:{
          value:'0'
        }
      };
    },
  components:{
    'input-box':{
      props:['inputShow'],
      computed: {
        value:function () {
          return this.inputShow.value
        }
      },
      template:'<input id="input-box" type="text" size="21" maxlength="21" v-model="value" readonly="readonly">'
    },
    'btn-list':{
      template:'<div id="btn-list"><slot></slot></div>'
    }
  },
    methods: {
      showDialog() {
        this.dialogVisible = true
      },
      handleClose() {
        this.dialogVisible = false
      },
      inputValue(param){
        if(Object.prototype.toString.call(this.inputShow.value) == "[object Number]"){   //判断输入框内容是否为数字类型
          this.inputShow.value = "0";   //数字类型说明是上个计算结果,清空内容
        }
        var str ='' + this.inputShow.value; //输入内容时,将输入框内容转为字符串类型
        var len = str.length;
        var arr = ["+","-","×","÷"];
        var num = str  //parseInt(str.split('').reverse().join('')))是获取输入框内最后一串数字,再反转回来 ,num为输入框内最后一串数字
        var nlen = num.length;
        if((num!= '0' && param != '.')||(param == '.'&& num.indexOf(".")==-1)){   //输入框内最后一串数字不为0时拼接字符串 &&this.inputShow.value.indexOf(".")==-1)||(this.inputShow.value=='0.'&&param!='.'
          if(arr.indexOf(str.charAt(len-1)) != -1 && arr.indexOf(param) != -1){    //若一开始输入内容为运算符,输入无效
            return;
          }
          this.inputShow.value += param;   //拼接输入内容
        }else{
          arr.push("%");
          let nus = str.replace(/[0-9]/g,'')
          if(param == '.'&&nus.charAt(nus.length - 1)=='.'){      //若num中已有小数点,输入内容为小数点,视为无效
            return;
          } else if(param == '.'&&nus.charAt(nus.length - 1)!='.'){
            this.inputShow.value = str + param;
          }else if(!(arr.indexOf(param) != -1)){    //判断输入框内最后一个字符不为运算符
            this.inputShow.value = str.substring(0,str.length-nlen) + param;  //输入框内最后一串数字为0时,删除0拼接
          }
        }
      },
    formatFloat(f, digit) { 
      var m = Math.pow(10, digit); 
      return parseInt(f * m, 10) / m; 
    },
    clearValue(){      //清空输入框内容
      this.inputShow.value = '0';
    },
    calValue(){       //计算结果
      var str = this.inputShow.value;
      if (typeof(str)=='string') {
        str = str.replace(/×/g,'*').replace(/÷/g,'/').replace(/%/g,'*0.01');    //替换运算符
      } else {
        return
      }
      try{
        this.inputShow.value = eval(str);      //若用户输入内容不符合运算规则,不计算
        this.inputShow.value = this.formatFloat(this.inputShow.value,1)
      }catch(error){
        return;
      }
    },
    squareValue(){             //平方计算
      var str = this.inputShow.value;
      this.inputShow.value = Math.pow(eval(str),2)
      this.inputShow.value = this.formatFloat(this.inputShow.value,1)
    },
    radicalValue(){             //开根号计算
      var str = this.inputShow.value;
      this.inputShow.value = Math.sqrt(eval(str));
    },
    backValue(){              //删除键,删除单个字符
      var str = this.inputShow.value;
      if(str.length == 1){
        this.inputShow.value = "0";
      }else{
         this.inputShow.value = str.slice(0,str.length-1);
      }
    },
    }
}
</script>
<style lang="scss" scoped>
body, ul, dl, dd, dt, ol, li, p, h1, h2, h3, h4, h5, h6, textarea, form, select, fieldset, table, td, div, input{margin:0;padding:0;-webkit-text-size-adjust:none}
h1, h2, h3, h4, h5, h6{font-size:12px;font-weight:normal}
body>div{margin:0 auto}
div{text-align:left}
a img{border:0}
body{color:#333;text-align:center;font:12px "微软雅黑";}
ul, ol, li{list-style-type:none;vertical-align:0}
a{outline-style:none;color:#535353;text-decoration:none}
a:hover{color:#D40000;text-decoration:none}
.clear{height:0;overflow:hidden;clear:both}
/* calculator */
#calculator{width:200px;height:245px;padding:10px;border:1px solid #e5e5e5;background:#f8f8f8;-moz-border-radius:3px;-webkit-border-radius:3px;border-radius:3px;box-shadow:0px 0px 10px #f2f2f2;-moz-box-shadow:0px 0px 10px #f2f2f2;-webkit-box-shadow:0px 0px 10px #f2f2f2;margin:0 auto;}
#calculator #input-box{margin:0;width:187px;padding:9px 5px;height:14px;border:1px solid #e5e5e5;border-radius:3px;-webkit-border-radius:3px;-moz-border-radius:3px;background:#FFF;text-align:right;line-height:14px;font-size:12px;font-family:Verdana, Geneva, sans-serif;color:#666;outline:none; text-transform:uppercase;}
#calculator #btn-list{width:200px;overflow:hidden;}
#calculator #btn-list .btn-radius{border-radius:2px;-webkit-border-radius:2px;-moz-border-radius:2px;border:1px solid #e5e5e5;background:-webkit-gradient(linear, 0 0, 0 100%, from(#f7f7f7), to(#ebebeb));background:-moz-linear-gradient(top, #f7f7f7,#ebebeb);filter:progid:DXImageTransform.Microsoft.gradient(startColorstr=#f7f7f7,endColorstr=#ebebeb,grandientType=1);line-height:29px;text-align:center;text-shadow:0px 1px 1px #FFF;font-weight:bold;font-family:Verdana, Geneva, sans-serif;color:#666;float:left;margin-left:11px;margin-top:11px;font-size:12px;cursor:pointer;}
#calculator #btn-list .btn-radius:active{background:#ffffff;}
#calculator #btn-list .clear-marginleft{margin-left:0;}
#calculator #btn-list .font-14{font-size:14px;}
#calculator #btn-list .color-red{color:#ff5050}
#calculator #btn-list .color-blue{color:#00b4ff}
#calculator #btn-list .btn-30{width:29px;height:29px;}
#calculator #btn-list .btn-70{width:70px;height:29px;}
</style>