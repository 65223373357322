<template>
  <div class="cooperate">
    <div class="title">
      <!-- <img src="../../../assets/images/homePage/cooperate.png" alt="" width="37px"> -->
      <p>合作伙伴</p>
    </div>
    <div class="friendlyLinkImage">
        <!-- <el-row
          class="friendlyLinkItem"
          :gutter="10"
        > 
          <el-col :span="3"  v-for="item in cooperat"
          :key="item.id">
            <a :href="item.source" target="_blank" style="display:block;">
              <img :src="item.titleImg" width="90%" height="100" style="margin-top:3px;"/>
            </a>
            </el-col>
      </el-row> -->
      <div  class="friendlyLinkItem">
        <div v-for="item in cooperat" :key="item.id" class="items">
           <a :href="item.source" target="_blank" style="display:block;">
              <img :src="item.titleImg" width="90%" height="100" style="margin-top:3px;"/>
            </a>
        </div>
      </div>
   </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cooperat: [],
      current: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getCooperate();
  },
  methods: {
    getCooperate() {
      protocolFwd.param_getCooperateList.param.page = 0;
      protocolFwd.param_getCooperateList.param.size = 8;
      protocolFwd.param_getCooperateList.param.columnId = window.c.cooperate;
      http.getRes(protocolFwd.param_getCooperateList).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.cooperat = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.cooperate {
  height: 180px;
  .title{
    line-height: 40px;
    font-weight: 700;
    font-size: 22px;
    color: #5c5c5c;
    padding-bottom:20px;
    text-align:center;
    position: relative;
  }
  .title p{
    font-weight: 700;
    font-size: 22px;
  }
  .friendlyLinkItem{
    text-align:center;
  }
  .items{
    display:inline-block;
    width:12%;
    margin-left: 0.5%;
    border:1px solid transparent;
    
  }
 .items:hover{
      border-color:#0193DE;
    }
  // .title::before{
  //   content:'';
  //   position: absolute;
  //   left: 450px;
  //   top:16px;
  //   width:50px;
  //   height: 3px;
  //   border-top:1px solid #333;
  //   border-bottom:1px solid #333;
  // }
  // .title img{
  //   position: absolute;
  //   left: 510px;
  // }
  // .title::after{
  //   content:'';
  //   position: absolute;
  //   left: 650px;
  //   top:18px;
  //   width:50px;
  //   height: 3px;
  //   border-top:1px solid #333;
  //   border-bottom:1px solid #333;
  // }
  // .friendlyLinkImage{
  //   // background-color: #ffffff;
  //   padding:10px;
  //   .el-col-3 a{
  //     text-align:center;
  //     border:1px solid transparent;
  //   }
  //   .el-col-3 a:hover{
  //     border-color:#cf4640;
  //   }
  // }
  
}
</style>