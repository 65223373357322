<template>
  <div class="indexPage">
    <Banner ref="banner"/>
    <div class="goodsHall">
      <productDateZYQ v-if="bidFlagss == 0 || bidFlagss == 2"/>
      <div class="goodsHallContainer" style="margin-top: 15px" v-if="lstFlag">
        <el-row v-if="bidFlagss == 0 || bidFlagss == 1">
          <el-col :span="24">
            <!--  现货销售  -->
            <Spot/>
          </el-col>
          <el-col :span="24">
            <!--  现货采购  -->
            <Purchase/>
          </el-col>
          <el-col :span="24">
            <!--  产品预售  -->
            <newPreSell/>
          </el-col>
          <el-col :span="24">
            <!--  竞价交易  -->
            <priceCompetition/>
          </el-col>
        </el-row>
        <el-row class="secondLine" :gutter="20">
          <el-col :span="24">
            <cooperate/>
          </el-col>
        </el-row>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";

import Footer from "../base/footer/footerZYQ.vue";
import searchHeader from "../base/header/searchHeader";
import Banner from "./secondEdition/banner";
import Spot from "./secondEdition/spot"; // 现货销售
import Purchase from "./secondEdition/purchase"; // 现货采购
import priceCompetition from "./secondEdition/priceCompetition"; // 竞价交易
import newPreSell from "./secondEdition/newPreSell"; // 产品预售
import cooperate from "./secondEdition/cooperate"; // 合作伙伴
import productDateZYQ from "./secondEdition/productDate"; // 中远期商品
export default {
  components: {
    searchHeader,
    Banner,
    Spot,
    Purchase,
    priceCompetition,
    newPreSell,
    cooperate,
    productDateZYQ,
    Footer
  },
  data() {
    return {
      bidFlagss: 9999,
      userMes: null,
      lstFlag: window.g.LST_FLAG
    };
  },
  computed: {
    ...mapGetters(["firmListGetter"])
  },
  watch: {
    firmListGetter() {
      this.searchFirmId();
    }
  },
  mounted() {
    this.userMes = this.$store.state.common.sessionInfo;
    this.searchFirmId();
    let that = this;
    setTimeout(() => (window.onresize = () => {
      that.$refs.banner.calcBannerLeft();
    }), 200)
  },
  methods: {
    searchFirmId() {
      const that = this;
      var value = this.firmListGetter;
      this.$store.commit("setFirmList", value);
      localStorage.setItem("FirmIdList_w", JSON.stringify(value));
      if (value.length != 0) {
        sessionStorage.setItem("marketId_w", value[0].marketId);
        this.getIdentificationStatus(value[0].firmId)
        if (value[0].marketId == 18) {
          this.bidFlagss = 1;
          this.$store.commit("setIsFlagss", 1);
          localStorage.setItem("bidFlagss", 1);
        } else if (value[0].marketId == 28) {
          this.bidFlagss = 2;
          this.$store.commit("setIsFlagss", 2);
        }
      } else {
        this.bidFlagss = 0;
        this.$store.commit("setIsFlagss", 0);
        sessionStorage.setItem("marketId_w", null);
      }
      this.$store.commit("setFirmList", value);
      for (let i = 0; i < value.length; i++) {
        if (value[i].marketId == window.a.application[0].marketId) {
          localStorage.setItem("atPresentFirmId", value[i].firmId);
          that.queryCommodityStrategy();
          return;
        }
      }
    },
    getIdentificationStatus(firmIds) {
      // alert('sssss')
      protocolFwd.param_enterpriseCertification.param.firmId = firmIds;
      http
        .postFront(protocolFwd.param_enterpriseCertification)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            if (Object.keys(value).length !== 0) {
              sessionStorage.setItem("userFirmId", value.firm_id);
            } else {
              sessionStorage.setItem("userFirmId", firmIds);
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    queryCommodityStrategy() {
      const _this = this;
      http.postFrontGX(protocol.param_queryCommodityStrategy).then(response => {
        const {code, message, value} = response.data;
        if (code == 0) {
          this.$store.commit("setCommodityStrategy", value);
        } else {
          this.$EL_MESSAGE(message);
          setTimeout(() => {
            _this.queryCommodityStrategy();
          }, 1000);
        }
      }).catch(e => {
        setTimeout(() => {
          _this.queryCommodityStrategy();
        }, 1000);
      });
    }
  },
  beforeDestroy() {
    window.onresize = null;
  }
};
</script>
<style scoped>
.indexPage .goodsHall {
  width: 1200px;
  margin: 0 auto;
}

.indexPage .goodsHall .goodsHallTitle {
  height: 80px;
  line-height: 80px;
  text-align: center;
}

.indexPage .goodsHall .goodsHallTitle p {
  font-size: 24px;
  color: #666666;
}

.indexPage .goodsHall .secondLine {
  margin-top: 20px;
}
</style>
