<template>
  <div class="productData">
    <div class="topBox">
      <div class="t_left">
        <div
          :style="index == 3 ? 'margin-bottom: 0' : 'margin-bottom: 0.3vw'"
          class="tl_item"
          v-for="(item, index) in marketDetails"
          :key="index"
        >
          <el-card class="productItem" shadow="hover">
            <div @click="goPage(item)">
              <div class="top">
                <div>{{ item.symbol }}</div>
                <div style="display: flex;align-items: center;">
                  <i
                    v-if="item.quotechangeColor == 'red'"
                    class="el-icon-top"
                    :style="{ color: item.quotechangeColor }"
                  ></i>
                  <i
                    v-else
                    class="el-icon-bottom"
                    :style="{ color: item.quotechangeColor }"
                  ></i>
                  <div :style="{ color: item.quotechangeColor }">
                    {{ item.quotechange }}
                  </div>
                </div>
              </div>
              <div class="middle">
                <div>最新价</div>
                <div>{{ Number(item.close) | toThousands1 }}</div>
              </div>
              <div class="bottom">
                <div class="b1">
                  <div
                    class="b1_item"
                    style="display: flex;justify-content: flex-start;"
                  >
                    成交量
                  </div>
                  <!-- <div class="b1_item">成交笔数</div> -->
                  <div
                    class="b1_item"
                    style="display: flex;
    justify-content: flex-end;"
                  >
                    成交额
                  </div>
                </div>
                <div class="b2">
                  <div
                    class="b1_item"
                    style="display: flex;justify-content: flex-start;"
                  >
                    {{ item.amount }}
                  </div>
                  <!-- <div class="b1_item"></div> -->
                  <div
                    class="b1_item"
                    style="display: flex;
    justify-content: flex-end;"
                  >
                    {{ item.totalAmount | toThousands }}
                  </div>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <!-- <div class="t_right">
        <listModules />
      </div> -->
    </div>
    <!-- <el-row :gutter="10">
      <el-col
        :span="6"
        v-for="(item, index) in marketDetails"
        :key="index"
        v-if="index <= 3"
      >
        <el-card class="productItem" shadow="hover">
          <div @click="goPage(item)">
            <div class="top">
              <div>{{ item.symbol }}</div>
              <div :style="{ color: item.quotechangeColor }">
                {{ item.quotechange }}
              </div>
            </div>
            <div class="middle">{{ Number(item.close) | toThousands1 }}</div>
            <div class="bottom">
              <div class="b1">成交量{{ item.amount }}</div>
              <div class="b2">成交额{{ item.totalAmount | toThousands}}</div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row> -->
    <!-- 风险协议 -->
    <div id="dialog1">
      <el-dialog
        :visible.sync="dialogVisible"
        width="1100px"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
        style="min-width: 1000px"
      >
        <UserProtocolDY />
        <div class="btns">
          <el-button @click="cancel2" style="margin-right: 70px"
            >取消</el-button
          >
          <el-button @click="enter" :disabled="enterDisabled" type="primary">{{
            enterName
          }}</el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 购销弹窗 -->
    <div id="dialog2">
      <el-dialog :visible.sync="dialogVisible1" width="30%">
        <div>您好，您即将进行预售交易模式认证，请谨慎操作！</div>
        <div>
          <div class="d2_title">邀请码:</div>
          <el-input v-model="inviteInput" placeholder="请输邀请码"></el-input>
        </div>
        <div class="d2_btns">
          <el-button
            @click="inviteEnter"
            :disabled="isDisabled"
            type="primary"
            style="margin-left: 15px"
            >确认</el-button
          >
          <el-button @click="cancel1">取消</el-button>
        </div>
      </el-dialog>
    </div>
    <examinationPaper ref="examinationPaper" @enterInto="goPagess" />
    <el-dialog id="el_dig3" :visible.sync="identityFlag" width="40%">
      <identityModules @cancel="cancelDia_3" @popupEnter="popupEnter" />
    </el-dialog>
  </div>
</template>

<script>
import identityModules from "@/components/identityModule/identityModules";
import UserProtocolDY from "@/components/reglogin/register/UserProtocolDY";
import examinationPaper from "@/components/HomePage/component/children/examinationPaper";
import listModules from "./components/listModules";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    UserProtocolDY,
    examinationPaper,
    listModules,
    identityModules
  },
  data() {
    return {
      BDcertifiy: "",
      identityFlag: false,
      enterName: "确认",
      enterDisabled: true,
      isDisabled: false,
      dialogVisible1: false,
      dialogVisible: false,
      userMes: null,
      id: null,
      inviteInput: ""
    };
  },
  computed: {
    ...mapGetters(["marketDetails", "marketStatusGetter"])
  },
  created() {
    this.userMes = this.$store.state.common.sessionInfo;
    console.log("行情数据", this.marketDetails);
  },
  mounted() {
    const that = this;
    // this.$nextTick(function() {
    //   for (let i = 0; i < 2; i++) {
    //     that.marketDetails.push(that.marketDetails[0]);
    //   }
    // });
    console.log(this.marketDetails, "this.marketDetails");
  },
  methods: {
    cancelDia_3() {
      this.identityFlag = false;
    },
    popupEnter() {
      console.log("确认");
      this.identityFlag = false;
      this.$router.push("/personalEnterpriseAuth");
    },
    // 协议阅读时间
    agreementOpen() {
      var that = this;
      var time = window.g.AGREEMENT_TIME;
      var interval = setInterval(() => {
        time = Number(time) - 1000;
        if (time <= 0) {
          that.enterName = "确认";
          clearInterval(interval);
          that.enterDisabled = false;
        } else {
          that.enterDisabled = true;
          that.enterName = `确认(${Number(time) / 1000}s)`;
        }
      }, 1000);
    },
    goPagess() {
      var that = this;
      var arr1 = this.$store.state.search.firmList;
      // if (arr1.length > 0 && arr1[0].marketId == 28) {
      if (arr1.length > 0) {
        // 已开通,检测是否通过协议
        this.getAgreementEnter();
      } else {
        // 未开通市场
        var gxFlag = false;
        // var arr1 = this.$store.state.search.firmList;
        // 答题通过,打开开通市场弹窗
        this.dialogVisible1 = true;
      }
      // for (var a = 0; a < arr1.length; a++) {
      //   if (arr1[a].marketId == 28) {
      //     gxFlag = true;
      //   }
      // }
      // const userId = this.$store.state.common.sessionInfo.userId;
      // const isUserList = JSON.parse(localStorage.getItem("userList"));
      // const currentDay = util.formatnewDay(new Date());
      // const obj = {};
      // obj.userId = userId;
      // obj.time = currentDay;
      // let count = 0;
      // console.log(isUserList);
      // if (isUserList === null) {
      //   this.dialogVisible = true;
      //   this.agreementOpen();
      // } else if (isUserList.length !== 0) {
      //   for (let i = 0; i < isUserList.length; i++) {
      //     if (
      //       isUserList[i].userId === userId &&
      //       isUserList[i].time === currentDay
      //     ) {
      //       that.dialogVisible = false;
      //       if (gxFlag) {
      //         that.gxPush();
      //       } else {
      //         this.dialogVisible1 = true;
      //       }
      //       return;
      //     } else {
      //       count = count + 1;
      //     }
      //     if (count === isUserList.length) {
      //       this.dialogVisible = true;
      //       this.agreementOpen();
      //     }
      //   }
      // }
      // const content = JSON.parse(localStorage.getItem("userList")) || [];
      // if (content.length !== 0) {
      //   let m = 0;
      //   for (let i = 0; i < content.length; i++) {
      //     if (content[i].userId === userId) {
      //       content.splice(i, 1);
      //       content.push(obj);
      //     } else {
      //       m = m + 1;
      //     }
      //   }
      //   if (m === content.length) {
      //     content.push(obj);
      //   }
      // } else {
      //   content.push(obj);
      // }
      localStorage.setItem("userList", JSON.stringify(content));
    },
    // 弹窗确认(风险告知书)
    enter() {
      this.dialogVisible = false;
      this.gxPush();
      this.agreementEnter();

      // var gxFlag = false;
      // var arr1 = this.$store.state.search.firmList;
      // for (var a = 0; a < arr1.length; a++) {
      //   if (arr1[a].marketId == 28) {
      //     gxFlag = true;
      //   }
      // }
      // this.dialogVisible = false;
      // if (gxFlag) {
      //   this.gxPush();
      // } else {
      //   this.dialogVisible1 = true;
      // }
    },
    agreementEnter() {
      http.postFront(protocol.param_enterAgreement).then(response => {});
    },
    cancel1() {
      //   this.activeIndexs = 0
      this.inviteInput = "";
      this.$router.push("/HomePage1");
      this.dialogVisible1 = false;
      console.log(this.activeIndexs, 1);
    },
    searchFirmId() {
      protocolFwd.param_firmIdSearch.param.userId = this.userMes.userId;
      http.postFront(protocolFwd.param_firmIdSearch).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          this.$store.commit("setFirmList", value);
          localStorage.setItem("setFirmList_w", JSON.stringify(value));
          if (value.length != 0) {
            this.isAssets = true;
            if (value[0].marketId == 18) {
              this.bidFlagss = 1;
              localStorage.setItem("bidFlagss", 1);
            } else if (value[0].marketId == 28) {
              this.bidFlagss = 2;
              localStorage.setItem("bidFlagss", 2);
            }
          } else {
            this.isAssets = false;
          }
        }
      });
    },
    inviteEnter() {
      const that = this;
      sessionStorage.setItem("marketId_w", 28);
      this.isDisabled = true;
      if (this.inviteInput == "") {
        this.$EL_MESSAGE("请填写邀请码");
        this.isDisabled = false;
        return;
      }
      // protocol.param_dredgeBazaars.param.userId = this.userMes.userId;
      // protocol.param_dredgeBazaars.param.marketId = 28;
      protocol.param_dredgeBazaars.param.lnvitationCode = this.inviteInput.replace(
        /\s*/g,
        ""
      );
      protocol.param_dredgeBazaars.param.lnvitationCodeMustFillIn = true;
      http.postFrontMarket(protocol.param_dredgeBazaars).then(response => {
        var { message, code, value } = response.data;
        if (Number(code) === 0) {
          setTimeout(() => {
            that.isDisabled = false;
          });
          // 点击开通市场
          // 查询是否确认协议
          this.getAgreementEnter();
          this.searchFirmId();
          this.$EL_MESSAGE("成功");
          this.dialogVisible1 = false;
          this.inviteInput = "";
          localStorage.setItem("atPresentFirmId", value);
          //   this.activeIndexs = "1";
          // this.gxPush();
        } else {
          that.isDisabled = false;
          this.searchFirmId();
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 查询是否确认协议
    getAgreementEnter() {
      http.postFront(protocol.param_agreementEnter).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入购销市场
            this.gxPush();
          } else {
            // 未通过,打开协议弹窗
            this.dialogVisible = true;
            this.agreementOpen();
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    cancel2() {
      const userId = this.$store.state.common.sessionInfo.userId;
      const content = JSON.parse(localStorage.getItem("userList")) || [];
      if (content.length !== 0) {
        let m = 0;
        for (let i = 0; i < content.length; i++) {
          if (content[i].userId === userId) {
            content[i].userId = 1;
          }
        }
        localStorage.setItem("userList", JSON.stringify(content));
      } else {
        localStorage.setItem("userList", JSON.stringify(content));
      }
      this.$router.push("/HomePage1");
      this.dialogVisible = false;
    },
    // 购销商品进入(新手引导)
    goPage(item) {
      this.id = item.symbolId;
      localStorage.setItem("symbolIds", item.symbolId);
      // 查询协议是否通过
      http.postFront(protocol.param_agreementEnter).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入购销市场
            this.gxPush();
          } else {
            // 未通过,进入新手引导
            this.$router.push("/guide");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // (旧)购销商品进入 2021年5月19日15:04:42修改
    goPage2(item) {
      this.id = item.symbolId;
      localStorage.setItem("symbolIds", item.symbolId);
      // 查询认证类型
      http.postFront(protocol.param_queryCertificationType).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          this.auditType = value;
          if (value && value.auditType) {
            if (value.auditType === "P") {
              // 个人,查询百度认证
              // this.goPage1();
              this.getBDcertifiy();
            } else if (value.auditType === "O") {
              // 企业,查询实名认证
              this.goPage1();
            }
          } else {
            // 未选择认证类型
            // this.identityFlag = true;
            this.$router.push("/certificationTypes");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 查询百度认证
    getBDcertifiy() {
      http
        .postFront(protocol.param_getIdentificationInfo)
        .then(response => {
          const { code, message, value } = response.data;
          if (code === "0" || code === 0) {
            if (
              value !== null &&
              JSON.stringify(value) !== "{}" &&
              value !== "0"
            ) {
              // 百度已认证,进入实名认证页面
              console.log("百度已认证,进入实名认证页面");
              this.BDcertifiy = "已认证";
              this.goPage1();
            } else {
              // 百度未认证,进入选择认证方式页面
              console.log("未认证,打开选择认证方式弹窗");
              this.BDcertifiy = "未认证";
              this.$router.push("/certificationTypes");
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    goPage1() {
      var that = this;
      // console.log(item, "点击商品");
      // this.id = item.symbolId;
      // localStorage.setItem("symbolIds", item.symbolId);

      http
        .postFront(protocolFwd.param_findBaseUserAuditByUserId)
        .then(response => {
          const { code, message, value } = response.data;
          if (Number(code) === 0) {
            if (value == null) {
              // 判断是个人还是企业
              if (this.auditType.auditType == "P") {
                // 个人,进入实名认证页面
                this.$router.push("/personalEnterpriseAuth");
              } else {
                // 企业,进入选择认证方式页面
                this.$router.push("/certificationTypes");
              }
            } else {
              // 审核状态（0:未审核 1:已认证 2:已驳回）
              if (value.auditStatus == 1) {
                // 认证通过,进入开通市场流程
                this.marketFlag = 3;
                var that = this;
                var arr = JSON.parse(localStorage.getItem("marketList_w"));
                for (let i = 0; i < arr.length; i++) {
                  if (arr[i].marketId == 28) {
                    if (arr[i].openFlag == "已开通") {
                      if (!JSON.parse(localStorage.getItem("examination_w"))) {
                        // 检测是否通过答题
                        // 未通过,打开答题弹窗
                        this.$refs.examinationPaper.isShow();
                      } else {
                        // 已通过,检测是否开通市场
                        var arr1 = this.$store.state.search.firmList;
                        // if (arr1.length > 0 && arr1[0].marketId == 28) {
                        if (arr1.length > 0) {
                          // 已开通,检测是否通过协议
                          this.getAgreementEnter();
                        } else {
                          // 未开通,打开开通市场弹窗
                          this.dialogVisible1 = true;
                        }

                        // that.goPagess();
                      }
                    } else {
                      this.$EL_MESSAGE("暂未开放");
                    }
                  }
                }
              } else if (value.auditStatus == 0) {
                this.$EL_MESSAGE("请等待管理员审核");
                this.$router.push("/personalEnterpriseAuth");
              } else if (value.auditStatus == 2) {
                this.$EL_MESSAGE("'认证被驳回，请重新认证'");
                this.$router.push("/personalEnterpriseAuth");
              }
            }
          } else {
            this.$EL_MESSAGE("请先进行实名认证");
            this.$router.push("/personalEnterpriseAuth");
          }
        })
        .catch(err => {
          this.$EL_MESSAGE(err);
        });

      // var arr = this.$store.state.search.firmList
      // var firmId = 0
      // for (let i = 0; i < arr.length; i++) {
      //     if (arr[i].marketId == 28) {
      //         firmId = arr[i].firmId
      //         localStorage.setItem('atPresentFirmId', firmId)
      //     }
      // }
      // var items = {
      //     carousel: 168,
      //     icon: "det.png",
      //     iconSelect: "det.png",
      //     marketId: 28,
      //     name: "预售交易",
      //     notice: 167,
      //     url: "./static/dist_dy/index.html?" + `symbolId=${item.symbolId}`
      // }
      // this.$store.commit('setHeaderText', items)
      // localStorage.setItem('setHeaderText', JSON.stringify(items))
      // localStorage.setItem('symbolIds', item.symbolId)
      // // localStorage.setItem('setHeaderText', null)
      // // localStorage.setItem('iframeUrl', JSON.stringify(items))
      // this.$router.push('/iframeController')
      // console.log(item.symbolId)
    },
    gxPush() {
      var arr = this.$store.state.search.firmList;
      var firmId = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].marketId == 28) {
          firmId = arr[i].firmId;
          localStorage.setItem("atPresentFirmId", firmId);
        }
      }
      var item = {
        carousel: 168,
        icon: "det.png",
        iconSelect: "det.png",
        marketId: 28,
        name: "预售交易",
        notice: 167,
        url: "./static/dist_dy/index.html#/transCC"
      };
      this.$store.commit("setHeaderText", item);
      localStorage.setItem("setHeaderText", JSON.stringify(item));
      this.$router.push("/moduleYSJY");
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ #dialog1 .el-dialog {
  height: 80%;
  overflow: hidden;
  overflow-y: auto;
  min-width: 1000px !important;
}
/deep/ #dialog1 .el-dialog::-webkit-scrollbar {
  width: 4px;
}
/deep/ #dialog1 .el-dialog::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
/deep/ #dialog1 .el-dialog::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.productData {
  margin: 15px 0;
  .productItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    // border: 1px solid #ccc;
    // padding: 15px;
    cursor: pointer;
    .top {
      display: flex;
      justify-content: space-between;
    }
    .middle {
      margin: 20px 0;
      display: flex;
      justify-content: space-between;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      .b1 {
        display: flex;
      }
      .b2 {
        display: flex;
      }
      .b1_item {
        width: 50%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.btns {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.d2_title {
  margin: 10px 0;
}
.d2_btns {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
.topBox {
  display: flex;
  justify-content: space-between;
  // height: 41vw;
  overflow: hidden;
  .t_left {
    width: 100%;
    display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    .tl_item {
      width: 25%;
      margin-bottom: 0.3vw;
      padding: 1vw;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
    }
  }
  .t_right {
    border-left: 1px solid #e4e7ed;
    border-right: 1px solid #e4e7ed;
    border-bottom: 1px solid #e4e7ed;
    width: 78%;
    height: 98%;
    overflow: hidden;
  }
}
</style>
