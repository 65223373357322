<template>
  <div class="indexBanner">
    <div class="bannerImgBox" id="slider">
      <el-carousel height="397px">
        <el-carousel-item v-for="item in carouselDatas" :key="item.id">
          <!-- <div
            class="carouselItem"
            :style="'background: url(' + item.titleImg + '); background-size: 100% 100%'"
          ><a :href="item.source" target="_blank" style="display: block;width:100%;height:100%;"></a></div> -->
          <a
            :href="item.source"
            target="_blank"
            style="display: block;width:100%;text-align:left;background-color: #f7f7f7"
          >
            <img
              :src="item.titleImg"
              alt=""
              style="width: 100%;height: 397px;"
            />
          </a>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="bannerContent">
      <!-- 导航 -->
<!--       <category />-->
      <!-- 公告 -->
      <!-- <div class="noticeList">
        <div v-if="sessionInfoGetter.isLogin=='true'">
          <div class="logo">
            <div class="logoPic">
              <img :src="logoUrlGetter" />
            </div>
            <div class="accountCenter">
              <p @click="goAccountCenter()">进入用户中心</p>
            </div>
          </div>
        </div>
        <div v-else class="noLogin">
          <div class="logos">
            <div class="logoPics">
              <img src="../../../assets/images/homePage/logo.png" width="60px"/>
            </div>
          </div>
          <div class="rightContent">
            <p>Hi,您好！欢迎来到三朵云</p>
            <div class="operateButton">
              <div class="buttonItem loginButton" @click="goLogin()">登录</div> <span style="color:#999">/</span>
              <div class="buttonItem registerButton" @click="goRegister()">注册</div>
            </div>
          </div>
          <div class="operateButton">
            <div class="buttonItem loginButton" @click="goLogin()">登录</div>
            <div class="buttonItem registerButton" @click="goRegister()">注册</div>
          </div>
        </div>
        <div class="noticeTitle" @click="goNotice()">
          <p class="title">公告</p>
          <p class="more">查看更多</p>
        </div>
        <div style="height:150px">
          <div
          class="noticeItem"
          v-for="item in noticeDatas"
          :key="item.id"
          @click="goNoticeDetails(item.id)"
          >
            <p class="itemText">{{ item.title }}</p>
            <p class="itemDate">{{ item.createTime | DateFormate }}</p>
          </div>
        </div>
        <div class="sixBox">
          <el-row>
            <el-col :span="8">
              <img src="../../../assets/images/homePage/order.png" alt="" width="20px">
              <p>我的订单</p>
            </el-col>
            <el-col :span="8">
                <img src="../../../assets/images/homePage/jm.png" alt="" width="20px">
              <p>招商加盟</p>
            </el-col>
            <el-col :span="8">
                 <img src="../../../assets/images/homePage/logic.png" alt="" width="20px">
              <p>物流查询</p>
            </el-col>
            <el-col :span="8">
              <router-link tag="li" :to="{ name: 'helpCenter'}">
                   <img src="../../../assets/images/homePage/helpzn.png" alt="" width="20px">
             <p> 新手指南</p>
              </router-link>
            </el-col>
            <el-col :span="8">
              <div @click="getCustomer">
                <img src="../../../assets/images/homePage/kf.png" alt="" width="20px">
                <p>客服</p>
              </div>
            </el-col>
            <el-col :span="8">
              <div @click="showCalculators">
                <img src="../../../assets/images/homePage/calculator.png" alt="" width="20px">
                <p>计算器</p>
              </div>

            </el-col>
          </el-row>
        </div>
      </div> -->
    </div>
<!--    <Calculator ref="showCalculator" />-->
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import category from "../../base/category/category";
import Calculator from "./Calculator";
export default {
  data() {
    return {
      carouselDatas: [],
      noticeDatas: [],
      bannerContentLeft: null,
      resizeWindow: null
    };
  },
  components: {
    category,
    Calculator
  },
  computed: {
    ...mapGetters(["sessionInfoGetter", "logoUrlGetter"])
  },
  created() {
    // this.calcBannerLeft();
  },
  mounted() {
    // let clickCustore = document.getElementById('offline-icon')
    // clickCustore.style.display = "none"
    const that = this;
    setTimeout(function() {
      that.getCarouselDatas();
    }, 500);
    setTimeout(function() {
      that.getNoticeDatas();
    }, 1000);
  },
  beforeDestroy() {
    // this.glHelpMessage(1);
    // this.glHelpMessage(this.tradingObject.otherother);
  },
  methods: {
    calcBannerLeft() {
      const windowWidth = window.innerWidth;
      if (windowWidth * 0.8 > 1300) {
        this.bannerContentLeft = "10%";
      } else if (windowWidth < 1300) {
        this.bannerContentLeft = 0;
      } else if (windowWidth * 0.8 <= 1300 && windowWidth >= 1300) {
        this.bannerContentLeft = (windowWidth - 1300) / 2 - 8 + "px";
      }
    },
    showCalculators() {
      this.$refs.showCalculator.showDialog();
    },
    getCustomer() {
      // let clickCustore = document.getElementById('offline-icon')
      // clickCustore.onclick()
      // this.glHelpMessage(2)
    },
    getCarouselDatas() {
      protocolFwd.param_getContentTitleList1.param.page = 0;
      protocolFwd.param_getContentTitleList1.param.size = 10;
      // protocolFwd.param_getContentTitleList1.param.columnId = window.c.carousel;
      // protocolFwd.param_getContentTitleList1.param.columnIdList = [window.c.carousel];
      http.getRes(protocolFwd.param_getContentTitleList1).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.carouselDatas = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getNoticeDatas() {
      console.log("公告请求");
      protocolFwd.param_getContentTitleListCarousel.param.page = 0;
      protocolFwd.param_getContentTitleListCarousel.param.size = 4;
      protocolFwd.param_getContentTitleListCarousel.param.columnId = 167
        // window.c.notice;
      http
        .getRes(protocolFwd.param_getContentTitleListCarousel)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            this.noticeDatas = value.content;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    goLogin() {
      this.$router.push("/login");
    },
    goRegister() {
      this.$router.push("/register/info");
    },
    goAccountCenter() {
      this.$router.push("/personalCenter/accountInfo");
    },
    goNoticeDetails(id) {
      this.$router.push({
        name: "noticeDetails",
        params: {
          id: id
        }
      });
    },
    goNotice() {
      this.$router.push("/notice");
    }
  }
};
</script>
<style lang="scss" scoped>
.indexBanner {
  position: relative;
  width: 100%;
}
.indexBanner .bannerImgBox {
  position: absolute;
  min-height: 397px;
  width: 100%;
  min-width: 1200px;
  margin: 0 auto;
  text-align: center;
}
.indexBanner .bannerImg {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 397px;
}
.indexBanner .bannerImgBox .carouselItem {
  position: absolute;
  top: 0;
  left: 0px;
  bottom: 0;
  right: 0px;
  height: 397px;
}
.indexBanner .bannerContent {
  position: relative;
  margin: 0 auto;
  width: 1270px;
  // width: 80%;
  // min-width: 1300px;
  height: 100%;
  min-height: 397px;
  // z-index:222;
}
.indexBanner .bannerContent .navList {
  position: absolute;
  top: 0;
  left: 0;
  width: 270px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
}
.indexBanner .bannerContent .navList * {
  color: #ffffff;
}
.indexBanner .bannerContent .navList .navItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
  height: 20%;
  line-height: 22px;
}
.indexBanner .bannerContent .navList .navItem .navItemTitle {
  font-size: 15px;
}
.indexBanner .bannerContent .navList .navItem .navItemText {
  margin-right: 5px;
  font-size: 14px;
}
.indexBanner .bannerContent .noticeList {
  position: absolute;
  right: 0;
  top: 2.5%;
  width: 240px;
  height: 95%;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 1000;
}
.indexBanner .bannerContent .noticeTitle,
.indexBanner .bannerContent .noticeItem {
  height: 9%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}
.indexBanner .bannerContent .noticeTitle {
  border-bottom: 1px solid #f2f2f2;
}
.indexBanner .bannerContent .noticeItem {
  height: 37px;
  padding: 0 10px;
  border-bottom: 1px dashed #f2f2f2;
  cursor: pointer;
}
.indexBanner .bannerContent .noticeItem * {
  color: #666666;
}
.indexBanner .bannerContent .noticeItem .itemText,
.indexBanner .bannerContent .noticeItem .itemDate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.indexBanner .bannerContent .noticeItem .itemText {
  width: 210px;
}
.indexBanner .bannerContent .noticeTitle .title {
  padding-left: 10px;
  float: left;
  color: $commonThemeColor;
}
.indexBanner .bannerContent .noticeTitle .more {
  padding-right: 10px;
  float: right;
  color: $commonThemeColor;
  cursor: pointer;
}
.indexBanner .bannerContent .noticeList .logo {
  height: 31%;
  padding-bottom: 5px;
  // border-bottom: 5px solid #e7ebee;
  box-sizing: border-box;
}
.indexBanner .bannerContent .noticeList .logo .logoPic {
  position: relative;
  top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 56px;
  margin: 0 auto;
  color: #ffffff;
}
.indexBanner .bannerContent .noticeList .logo .logoPic img {
  max-height: 100%;
  max-width: 100%;
}
.indexBanner .bannerContent .noticeList .logo .accountCenter {
  position: relative;
  top: 10%;
  margin: 0 auto;
  width: 80%;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  background-color: #cf4640;
  // color: #ca9763;
  // background-color: #faeee0;
  cursor: pointer;
}
.indexBanner .bannerContent .noticeList .noLogin {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 90px;
}

.indexBanner .bannerContent .noticeList .rightContent {
  padding-left: 10px;
}
.indexBanner .bannerContent .noticeList .rightContent p {
  line-height: 40px;
  color: #333;
}
.sixBox {
  margin-top: 10px;
  text-align: center;
}
.sixBox .el-col-8 {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  height: 60px;
  cursor: pointer;
}
.sixBox .el-col-8 img {
  margin-top: 10px;
}
.indexBanner .bannerContent .rightContent .operateButton {
  text-align: center;
  cursor: pointer;
  background-color: #c6371d;
  border-radius: 20px;
}
.indexBanner .bannerContent .rightContent .buttonItem {
  display: inline-block;
  padding: 5px 15px;
  color: #f2f2f2;
}
.indexBanner .bannerContent .noticeList .logo .operateButton {
  position: relative;
  top: 10%;
  text-align: center;
  cursor: pointer;
}
.indexBanner .bannerContent .noticeList .logo .operateButton .buttonItem {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 3px;
}
.indexBanner .bannerContent .noticeList .logo .operateButton .loginButton {
  margin-right: 10px;
  color: #ffffff;
  background-color: $commonThemeColor;
}
.indexBanner .bannerContent .noticeList .logo .operateButton .registerButton {
  margin-left: 10px;
  color: $commonThemeColor;
  border: 1px solid $commonThemeColor;
}
</style>
