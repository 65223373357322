<template>
  <div class="priceCompetition" v-ripple.1000>
    <!-- <div class="priceCompetitionTitle">
      <span class="title">竞价交易</span>
      <span class="desc">交易充分，反映产品供需现状 </span>
      <span class="goPurchase" @click="goMall()">我要竞价</span>
    </div> -->
    <div class="priceCompetitionContainer">
      <el-row>
        <el-col :span="4">
          <span @click="mallClick"
            ><img
              src="../../../assets/images/homePage/jjia.jpg"
              style="cursor: pointer"
              width="100%"
              height="330px"
              alt=""
          /></span>
        </el-col>
        <el-col
          :span="4"
          v-for="(item, index) in priceCompetitionDatas"
          :key="index"
        >
          <div class="purchaseOrderItem" @click="goMallDetails(item)">
            <div class="newSoptStyle">
              <h1 class="title">{{ item.className }}</h1>
              <!-- <div class="info infoQ">
                <span>重量: <i class="garyColor">{{ item.hangWeight }}{{ item.unit }}</i></span>
                <span class="rightTitle">{{ item.specificationsName|keyAttributeTransformate }} <i class="garyColor">{{item.specificationsName|valueAttributeTransformate}}</i></span>
              </div> -->
              <div>
                <div class="priceQ priceJJ">
                  <span class="startPrice">起拍总价：</span>
                  <p style="width: 80%; margin-left: 15%; text-align: center">
                    ￥ <span class="fontStyle">{{ item.startPrice }}</span>
                  </p>
                  <i></i>
                </div>
                <!-- <p class="isNegotiable">{{item.bargainingStatus=='T'?'价格可议':'价格不可议'}}</p> -->
                <p class="isNegotiable">{{ item.statusName }}</p>
              </div>
              <div class="bottomBox">
                <p class="date">
                  竞价时间:<span style="font-size: 12px"
                    >{{ item.startTime | DateFormateNoYearAndSeconds }}-{{
                      item.endTime | DateFormateNoYearAndSeconds
                    }}</span
                  >
                </p>
                <!-- <p class="openTicket">开票期限：<span >{{ item.openTicketTime | DateFormateDay }}</span></p> -->
                <p class="firstNames">交收仓库: {{ item.sname }}</p>
                <p class="localName">
                  <span class="firstName">{{ item.hangEnterpriseName }}</span
                  ><span class="more">></span>
                </p>
              </div>
            </div>
            <!-- <p class="statuss info">
              <i class="leftAngle"></i>
              <span class="statusText">{{ item.statusName }}</span>
              <span class="buy">{{item.hangResourcesType | biddingTypeFormate}}</span>
            </p>
            <p class="title">
            {{ item.className }} <span class="espli">{{ item.specificationsName|firstAttributeTransformate  }} {{ item.hangWeight }}{{item.unit
              }}</span></p>
            <p class="info price">
              <span >起拍总价:{{ item.startPrice }}元</span>
            </p>

            <p class="date">{{ item.startTime | DateFormateNoYearAndSeconds }} - {{ item.endTime | DateFormateNoYearAndSeconds }}</p>
            <p class="info">
              <span class="company">{{item.hangResourcesType == 'Q'? item.pickEnterpriseName : item.hangEnterpriseName}}</span>
              <span class="goIview">去查看</span>
            </p> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 挂牌/竞价弹窗 -->
    <div id="dialog3">
      <el-dialog :visible.sync="dialogVisible2" width="30%">
        <!-- <div>您好，您即将进行{{ lstName }}市场绑定，请谨慎操作！</div> -->
        <div>您好，您即将进行现货{{ lstName }}模式，请确认！</div>
        <div class="d2_btns">
          <el-button
            @click="lstEnter"
            :disabled="isDisabled"
            type="primary"
            style="margin-left: 15px"
            >确认</el-button
          >
          <el-button @click="cancel3">取消</el-button>
        </div>
      </el-dialog>
    </div>
    <examinationPaper ref="examinationPaper" @enterInto="goPagess" />
  </div>
</template>
<script>
import examinationPaper from "@/components/HomePage/component/children/examinationPaper";

import { mapGetters } from "vuex";
export default {
  components: {
    examinationPaper
  },
  data() {
    return {
      isProductFlag: null, // 商品标识 1 有商品id 2 无商品id
      isDisabled: false,
      lstName: "",
      priceCompetitionDatas: [],
      userMes: "",
      dialogVisible2: false,
      id: null
    };
  },
  computed: {
    ...mapGetters(["selectFirmApplyCommodityGetter"])
  },
  mounted() {
    this.userMes = this.$store.state.common.sessionInfo;
    this.getDatas();
  },
  methods: {
    goPagess() {
      if (this.isProductFlag == 1) {
        this.flagsss = 2;
        this.searchFirmId();
        this.lstName = "竞价";
        var lstFlag = false;
        var arr1 = this.$store.state.search.firmList;
        for (var a = 0; a < arr1.length; a++) {
          if (arr1[a].marketId == 18) {
            lstFlag = true;
          }
        }
        if (lstFlag) {
          this.getIdentificationStatus2(
            "/mall/productionDetails/" +
              this.id,
            "竞价"
          );
        } else {
          this.dialogVisible2 = true;
        }
      } else if (this.isProductFlag == 2) {
        this.flagsss = 1;
        this.searchFirmId();
        this.lstName = "竞价";
        var lstFlag = false;
        var arr1 = this.$store.state.search.firmList;
        for (var a = 0; a < arr1.length; a++) {
          if (arr1[a].marketId == 18) {
            lstFlag = true;
          }
        }
        if (lstFlag) {
          // this.activeIndexs = '2';
          this.getIdentificationStatus2(
            "/mall",
            "竞价"
          );
        } else {
          // localStorage.setItem("latFLag_w", 0);
          this.dialogVisible2 = true;
        }
      }
    },
    getIdentificationStatus2(url, name) {
      var arr = this.$store.state.search.firmList;
      var firmId = 0;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].marketId == 18) {
          firmId = arr[i].firmId;
          localStorage.setItem("atPresentFirmId", firmId);
        }
      }
      var items = {
        carousel: 168,
        icon: "det.png",
        iconSelect: "det.png",
        marketId: 28,
        name: name,
        notice: 167,
        url: url
      };
      this.$store.commit("setHeaderText", items);
      localStorage.setItem("setHeaderText", JSON.stringify(items));
      this.$router.push("/moduleBidding");
    },
    lstEnter() {
      sessionStorage.setItem("marketId_w", 18);
      var that = this;
      this.isDisabled = true;
      protocol.param_dredgeBazaar.param.userId = this.userMes.userId;
      protocol.param_dredgeBazaar.param.marketId = 18;
      protocol.param_dredgeBazaar.param.lnvitationCode = null;
      protocol.param_dredgeBazaar.param.lnvitationCodeMustFillIn = false;
      http.postFrontMarket(protocol.param_dredgeBazaar).then(response => {
        var { message, code, value } = response.data;
        if (Number(code) === 0) {
          setTimeout(() => {
            that.isDisabled = false;
          }, 1500);
          this.searchFirmId();
          this.$EL_MESSAGE("成功");
          this.dialogVisible2 = false;
          if ((this.flagsss = 1)) {
            this.getIdentificationStatus2(
              "/mall",
              "竞价"
            );
          } else if ((this.flagsss = 2)) {
            this.getIdentificationStatus2(
              "/mall/productionDetails/" +
                this.id,
              "竞价"
            );
          }
          localStorage.setItem("atPresentFirmId", value);
        } else {
          that.isDisabled = false;
          this.searchFirmId();
          this.$EL_MESSAGE(message);
        }
      });
    },
    getDatas() {
      protocolFwd.param_queryActionResourceScreening.param = {
        page: 0,
        size: 4,
        marketId: 18,
        sort: [{ property: "hangCreateTime", direction: "DESC" }]
      };
      http
        .postFront(protocolFwd.param_queryActionResourceScreening)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            let arr = [];
            let nowTime = Number(new Date());
            for (let i = 0; i < value.content.length; i++) {
              let element = value.content[i];
              // let startTime = element.startTime? Number(element.startTime) : 0
              // let endTime = element.endTime? Number(element.endTime) : 0
              element.DianPrice = util.floatDiv(
                element.startPrice,
                element.weight
              );
              // if (nowTime < startTime) {
              //   element['statusName'] = '公示中'
              // } else if (nowTime >= startTime && nowTime < endTime) {
              //   element['statusName'] = '竞拍中'
              // } else if (nowTime >= endTime) {
              //   element['statusName'] = '已结束'
              // }
              if (element.isFinish == 0 && element.isFlow == 0) {
                element["statusName"] = "公示中";
              } else if (element.isFinish == 1 && element.isFlow == 0) {
                element["statusName"] = "竞拍中";
              } else if (element.isFinish == 2 && element.isFlow == 0) {
                element["statusName"] = "已结束";
              } else if (element.isFlow == 1) {
                element["statusName"] = "流拍";
              }
              element["signUpStyle"] = "未报名";
              for (
                let j = 0;
                j < this.selectFirmApplyCommodityGetter.length;
                j++
              ) {
                const selectCommodity = this.selectFirmApplyCommodityGetter[j];
                if (selectCommodity.orderId == element.orderId) {
                  element["signUpStyle"] = "已报名";
                }
              }
              arr.push(element);
            }
            this.priceCompetitionDatas = arr;
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    goMall() {
      // this.$router.push('/mall');
    },
    searchFirmId() {
      protocolFwd.param_firmIdSearch.param.userId = this.userMes.userId;
      http.postFront(protocolFwd.param_firmIdSearch).then(response => {
        var { message, value, code } = response.data;
        if (Number(code) === 0) {
          this.$store.commit("setFirmList", value);
          localStorage.setItem("setFirmList_w", JSON.stringify(value));
          if (value.length != 0) {
            this.isAssets = true;
            if (value[0].marketId == 18) {
              this.bidFlagss = 1;
              this.$store.commit("setIsFlagss", 1);
              localStorage.setItem("bidFlagss", 1);
            } else if (value[0].marketId == 28) {
              this.bidFlagss = 2;
              this.$store.commit("setIsFlagss", 2);
              localStorage.setItem("bidFlagss", 2);
            }
          } else {
            this.$store.commit("setIsFlagss", 0);
            this.isAssets = false;
          }
        }
      });
    },
    cancel3() {
      this.$router.push("/HomePage1");
      this.dialogVisible2 = false;
      // this.activeIndexs = 0
    },
    // 更多(新手引导)
    mallClick() {
      if (!window.g.LST_FLAG) {
        this.$EL_MESSAGE("暂未开放");
        return;
      }
      this.isProductFlag = 2;
      var arr = JSON.parse(localStorage.getItem("marketList_w"));
      // 查询是否确认协议
      http.postFront(protocol.param_agreementEnter).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入挂牌/竞价市场
            this.getIdentificationStatus2(
              "/mall",
              "竞价"
            );
          } else {
            // 未通过,打开协议弹窗
            this.$router.push("/guide");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 进入商品详情(新手引导)
    goMallDetails(item) {
      this.isProductFlag = 1;
      this.id = item.hangResourcesId;
      var arr = JSON.parse(localStorage.getItem("marketList_w"));
      // 查询是否确认协议
      http.postFront(protocol.param_agreementEnter).then(response => {
        var { code, message, value } = response.data;
        if (Number(code) === 0) {
          if (value.userId != null) {
            // 协议通过,进入挂牌/竞价市场
            this.getIdentificationStatus2(
              "/mall/productionDetails/" +
                item.hangResourcesId,
              "竞价"
            );
          } else {
            // 未通过,打开协议弹窗
            this.$router.push("/guide");
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
.priceCompetition {
  // padding: 10px;
  height: 330px;
  // background-color: #ffffff;
}
.priceCompetition:hover {
  box-shadow: 0px 0px 3px 3px #ccc;
  transition: box-shadow 0.5s;
}
.priceCompetitionTitle {
  overflow: hidden;
}
.priceCompetitionTitle .title {
  font-weight: 700;
  font-size: 22px;
  color: #eea75e;
}
.priceCompetitionTitle .goPurchase {
  float: right;
  line-height: 22px;
  color: #666666;
  cursor: pointer;
}
// .priceCompetitionContainer {
//   margin-top: 20px;
// }
.purchaseOrderItem {
  position: relative;
  padding: 20px;
  height: 330px;
  line-height: 28px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-right-color: #ddd;
  box-sizing: border-box;
}

.purchaseOrderItem:hover {
  border: 1px solid #0193de;
  /* box-shadow: 0px 0px 3px 3px #ccc;
  transition: box-shadow 0.5s; */
}
.purchaseOrderItem p.title {
  color: #666666;
  position: relative;
  // overflow: hidden;
  // white-space: nowrap;
  // text-overflow: ellipsis;
}
.priceCompetitionTitle .desc {
  margin-left: 5px;
  color: #9999a2;
  font-size: 12px;
}
.purchaseOrderItem p.title .commodityInfo {
  font-size: 12px;
  color: #9999a2;
}
.purchaseOrderItem p.price,
.purchaseOrderItem p.date,
.purchaseOrderItem p.company {
  font-size: 12px;
  // color: #9999a2;
  overflow: hidden;
}

.purchaseOrderItem .info {
  color: #9999a2;
  overflow: hidden;
}
.purchaseOrderItem .price span {
  font-size: 10px;
}
.purchaseOrderItem .info .startPrice {
  float: right;
  /* color: $commonThemeColor; */
}
.purchaseOrderItem .info .company {
  width: 70%;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.purchaseOrderItem .info .goIview {
  float: right;
  color: #ffffff;
  background-color: #468be5;
  padding: 0 8px 0 5px;
  border-radius: 5px;
}
.purchaseOrderItem .status {
  /* position: absolute;
  top: 10px;
  right: 0; */
  display: block;
  width: 100%;
  line-height: 20px;
}
.purchaseOrderItem .statuss .leftAngle {
  float: left;
  width: 0;
  height: 0;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent #eea75e transparent transparent;
}
.purchaseOrderItem .statuss .statusText {
  float: left;
  display: block;
  padding: 0 8px 0 5px;
  line-height: 20px;
  font-size: 12px;
  color: #ffffff;
  background-color: #eea75e;
}
.purchaseOrderItem .statuss .buy {
  float: right;
  color: #666666;
}
.purchaseOrderItem p.title .grades {
  position: absolute;
  left: 0;
  top: -5px;
}
.purchaseOrderItem p.isGrade {
  text-indent: 40px;
}
.d2_btns {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
