<template>
  <div class="navList">
    <!--  -->
    <div
      class="navItem"
      :class="list.flag?'active':''"
      v-for="(list,indexs) in categoryDatas.slice(0,5)"
      :key="indexs"
      @mouseleave="isAllflag"
    >
      <div class="navItemTitle" @mouseenter="islistFlag(list)">
        {{ list.className }}
        <p v-if="list.children.length!=0" class="navP">
          <span
            class="twoTitle"
            v-for="(item,index) in list.children.slice(0,2)"
            :key="index"
          >{{ item.className}}&nbsp;&nbsp;&nbsp;</span>
        </p>
        <p class="rightCoin">
          <i class="el-icon-arrow-right"></i>
        </p>
      </div>
      <div class="navItemContent">
        <div v-if="list.flag" class="twoItem">
          <!-- <span v-if="item.children.length!=0">|</span> -->
          <p class="navItemText" v-for="item in list.children" :key="item.classId">
            <span class="grayColor">{{ item.className }}</span>&nbsp;&nbsp;&nbsp;
            <span
              class="treeItem"
              v-for="items in item.children"
              :key="items.classId"
              @click="goMall(list.className,item.className,items)"
            >|&nbsp; {{ items.className }}&nbsp;&nbsp;</span>
          </p>
          <p v-if="list.children.length==0" class="noData">暂无数据</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      categoryDatas: [],
    };
  },
  mounted() {
    this.getCommodityCategory();
  },
  methods: {
    islistFlag(list) {
      list.flag = !list.flag;
      for (let i = 0; i < this.categoryDatas.length; i++) {
        if (list.classId === this.categoryDatas[i].classId) {
          continue;
        } else {
          this.categoryDatas[i].flag = false;
        }
      }
    },
    isAllflag() {
      let value = this.categoryDatas;
      for (let i = 0; i < value.length; i++) {
        value[i].flag = false;
      }
    },
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          for (let i = 0; i < value.length; i++) {
            value[i].flag = false;
          }
          this.categoryDatas = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    goMall(oneClassName, trwoClassName, item) {
      sessionStorage.setItem("isFirst", item.classId);
      let obj = {};
      obj.oneClassName = oneClassName;
      obj.twoClassName = trwoClassName;
      obj.threeClassName = item.className;
      this.$store.commit("setSearchCommodity", obj);
      this.$router.push({
        name: "ResourceDocuments",
        params: {
          commodity: item.classId,
        },
      });
    },
  },
};
</script>
<style scoped>
.navList {
  position: absolute;
  top: 0;
  left: 0;
  width: 270px;
  height: 400px;
  /* overflow-y: hidden; */
  background-color: rgba(255,255,255,0.7);
  z-index: 1000;
  /* padding: 0 35px 0 25px; */
  box-sizing: border-box;
}
.navList * {
  color: #4b4744;
}
.navList .navItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 35px 20px 25px;
  height: 34px;
  line-height: 24px;
  border-bottom: 1px solid #ddd;
}
.navList .navItem .navP {
  overflow: hidden;
  height: 24px;
}

.navList .navItem .navItemTitle {
  font-size: 16px;
  position: relative;
}
.rightCoin {
  position: absolute;
  right: 0px;
  top: 3px;
}
.navList .navItem .navItemText {
  margin-right: 5px;
  font-size: 14px;
  margin-top: 10px;
}
.active {
  background-color: #45b035;
}

.twoTitle {
  color: #666;
  font-size: 12px;
}
.active .twoTitle,
.active .navItemTitle,
.active .rightCoin .el-icon-arrow-right {
  color: #ffffff;
}
.twoItem {
  position: absolute;
  left: 270px;
  width: 600px;
  height: 317px;
  overflow: hidden;
  top: 0;
  border-left: 1px solid #ddd;
  padding: 10px;
  background-color: rgba(255,255,255,0.7);
  border: 2px solid #45b035;
}
.twoItem .grayColor {
  color: #333;
}
.treeItem {
  cursor: pointer;
  color: #666;
}
.noData {
  text-align: center;
}
</style>
