<!-- 委托查询 -->
<template>
  <div id="TradeEntrustTheQuery">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <div v-for="(item, index) in marketDetails" :key="index">
          <el-tab-pane v-if="index <= 3"  :label="item.symbol" :name="index.toString()" />
        </div>
    </el-tabs>
    <div class="title">
        <div class="t_item">交易品种</div>
        <div class="t_item">品种代码</div>
        <div class="t_item">成交数量</div>
        <div class="t_item">委托价</div>
        <div class="t_item">委托类型</div>
        <div class="t_item">成交额</div>
    </div>
    <vue-seamless-scroll :data="memberDatas" :class-option="defaultOption" class="seamless-warp">
        <div class="content" v-for="(item, index) in memberDatas" :key="index">
            <div class="c_item">{{item.commodityName}}</div>
            <div class="c_item">{{item.commodityCode}}</div>
            <div class="c_item">{{item.quantity}}</div>
            <div class="c_item">{{ Number(Number(item.singlePrice).toFixed(coinSmallDigital(selectCommodityStrategy(item.commodityId)).coinDigitalNum)) | toThousands1 }}</div>
            <div class="c_item">{{item.bsFlag}}</div>
            <div class="c_item">{{Number(item.allPrice) | toThousands3}}</div>
        </div>
    </vue-seamless-scroll>
  </div>
</template>

<script>
/*global http protocol*/
import {
  mapGetters
} from 'vuex'
export default {
  name: 'TradeEntrustTheQuery',
  computed: {
    ...mapGetters(["marketDetails", "marketStatusGetter", "sessionInfoGetter", "commodityStrategyGetter"]),
    fastDisabled() {
      if (this.multipleSelection.length > 0) {
        return false
      }
      return true
    },
    defaultOption () {
        return {
          step: 0.2, // 数值越大速度滚动越快
          limitMoveNum: 9, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 3000 // 单步运动停止的时间(默认值1000ms)
       }
  }
  },
  data() {
    return {
    memberDatas:[],
      commodityIds: null,
      activeName: '0',
      isNetWrong: false,
      tableData: [],
      // 是否撤单
      dialogVisible: false,
      // 分页
      pageData: {
        total: null,
        pageSizes: [5, 10, 20, 30, 40, 50, 60],
        pageSize: 10,
        currentPage: 1
      },
      // 订单号
      sOrderId: '',
      tetqTableWidth: {
        bsFlag: 80,
        orderPrice: 100,
        orderQuantity: 100,
        ocFlag: 110,
        orderType: 110,
        State: 80,
        stopLossPrice: 110
      },
      timer: null,
      multipleSelection: [],
      // 是否快速撤单
      dialogVisible1: false,
      fastClickNum: 0,
      fastClickSuccessNum: 0,
      fastClickErrorNum: 0,
      OrderFrontSum: {
        'bsFlag': '',
        'commodityCode': '',
        'commodityId': 0,
        'commodityName': '',
        'createTime': 0,
        'firmId': 0,
        'frozeFee': 0,
        'frozeMargin': 0,
        'holdDetailId': 0,
        'ocFlag': '',
        'orderId': 0,
        'orderStatus': 0,
        'orderType': 0,
        'quantity': 0,
        'singlePrice': 0,
        'tradeDay': 0,
        'unDealQuantity': 0,
        'unitNumeral': 0,
        'updateTime': 0
      },
      typeValue: '',
      typeOptions: [{
        value: '1',
        label: '已委托'
      }, {
        value: '2',
        label: '已成交'
      }, {
        value: '3',
        label: '已撤单'
      }, {
        value: '4',
        label: '部分成交'
      }, {
        value: '5',
        label: '部分成交后撤单'
      }],
      orderTypeValue: '',
      orderTypeList: [],
      proList: [], // 商品筛选列表
      proMes: ''
    }
  },
  watch: {
    flag() {
      protocol.queryUserEntrusts.data.bsFlag = this.flag
      this.showData()
    },
    typeValue() {
      if (this.typeValue) {
        protocol.queryUserEntrusts.data.orderStatus = [this.typeValue]
      } else {
        protocol.queryUserEntrusts.data.orderStatus = null
      }
      this.pageData.currentPage = 1
      this.showData()
    },
    proMes() {
      if (this.proMes) {
        protocol.queryUserEntrusts.data.commodityId = this.proMes
      } else {
        protocol.queryUserEntrusts.data.commodityId = null
      }
      this.pageData.currentPage = 1
      this.showData()
    },
    h_orderTime() {
      this.showData()
    }
  },
  created() {
      console.log(this.marketDetails, '新组建')
  },
  beforeDestroy() {
    protocol.queryUserEntrusts.data.orderStatus = null
    protocol.queryUserEntrusts.data.orderType = null
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  mounted() {
      const that = this
    setTimeout(() => {
        protocol.queryUserEntrusts.param.bsFlag = this.flag
        that.commodityIds = that.marketDetails[0].symbolId
        that.showData()
      }, 1000)
    this.getIndexPage()
    this.setLanguageCss()
  },
  methods: {
    handleClick(tab) {
        console.log(this.commodityStrategyGetter)
        this.memberDatas = []
        this.commodityIds = this.marketDetails[tab.index].symbolId
        this.showData()
    },
    selectChange(val) {
      console.log(val)
    },
    selectCommodityStrategy(commodityId) {
      if (commodityId && commodityId !== '--' && this.commodityStrategyGetter.length > 0) {
        for (const CommodityStrategy of this.commodityStrategyGetter) {
          if (commodityId == CommodityStrategy.commodityId) {
            return CommodityStrategy
          }
        }
      } else if (commodityId == '--' && this.commodityStrategyGetter.length > 0) {
        return this.commodityStrategyGetter[0]
      }
      return {
        canBuy: '--',
        canDqDelivery: '--',
        canSell: '--',
        canShow: '--',
        canTqDelivery: '--',
        canTqTransfer: '--',
        canTransfer: '--',
        commodityCode: '--',
        commodityId: null,
        commodityName: '--',
        feeStrategyId: '--',
        guidePrice: '--',
        holdStrategyId: '--',
        margin: '--',
        marginStrategyId: '--',
        marginType: '--',
        marketPrice: '--',
        maxHoldAmount: '--',
        maxSingleTradeAmount: '--',
        maxUpDownWidth: '--',
        minAmountChange: 0.01,
        minPickupAmount: '--',
        minPriceChange: 0.01,
        minSingleTradeAmount: '--',
        offerEndTime: 0,
        openFee: '--',
        openFeeType: '--',
        pickupCountdown: '--',
        pickupStandard: '--',
        pickupStartTime: 0,
        tradeStrategyId: '--',
        transferFee: '--',
        transferFeeType: '--',
        unitNumeral: '--',
        unitQuantifier: '--'
      }
    },
    /**
     * s :selectCommodityStrategy(c)
     */
    coinSmallDigital(s) {
      const minPriceChange = s.minPriceChange // 最小数量变动单位
      const minAmountChange = s.minAmountChange // 价格价格变动单位
      let pricePoint = -Math.round(Math.log(minPriceChange) / Math.log(10))
      let amountPoint = -Math.round(Math.log(minAmountChange) / Math.log(10))
      if (pricePoint <= 0) {
        pricePoint = 0
      }
      if (amountPoint <= 0) {
        amountPoint = 0
      }
      return {
        // symbolId: this.item.symbolId,
        // numStr: /^(\-)*(\d+)\.(\d{0,6}).*$/,
        numStr: new RegExp('^(\\-)*(\\d+)\\.(\\d{0,' + pricePoint + '}).*$'),
        coinDigitalNum: pricePoint,
        quantityNum: amountPoint, // 数量
        quantityStr: new RegExp('^(\\-)*(\\d+)\\.(\\d{0,' + amountPoint + '}).*$') // 数量
      }
    },
    selectable(row, index) {
      return row.orderStatus == '已委托' || row.orderStatus == '部分成交' // isHidden 0-否 1-是 返回false不可选
    },
    fastCloseClick() {
      // const that = this
      // that.fastClickSuccessNum = 0
      // that.fastClickErrorNum = 0
      // that.fastClickNum = 0
      // for (const tableCellData of this.multipleSelection) {
      //   if (tableCellData.orderStatus == '已委托' || tableCellData.orderStatus == '部分成交') {
      //     that.fastClickNum++
      //     const withdrawLimitOrder = JSON.parse(JSON.stringify(protocol.withdrawLimitOrder))
      //     withdrawLimitOrder.data.orderId = tableCellData.orderId
      //     http.gPost(withdrawLimitOrder).then(response => {
      //       if (response.data && response.data.code == '0') {
      //         that.fastClickSuccessNum++
      //       } else {
      //         that.fastClickErrorNum++
      //       }
      //       that.isOVER()
      //     })
      //   }
      // }
      const that = this
      that.fastClickNum = 0
      let orderList = ''
      for (const tableCellData of this.multipleSelection) {
        if (tableCellData.orderStatus == '已委托' || tableCellData.orderStatus == '部分成交') {
          if (that.fastClickNum == 0) {
            orderList = orderList + String(tableCellData.orderId)
          } else {
            orderList = orderList + ',' + String(tableCellData.orderId)
          }
          that.fastClickNum++
        }
      }
      if (that.fastClickNum > 0) {
        that.fastClickNum = 0
        this.multipleSelection = []
        protocol.param_cancelFast.data.orderList = orderList
        http.gPost(protocol.param_cancelFast).then(response => {
          this.dialogVisible1 = false
          if (response.data && response.data.code == '0') {
            this.$EL_MESSAGE(response.data.value)
          } else {
            this.$EL_MESSAGE(response.data.message)
          }
        })
      }
    },
    handleSelectionChange(val) {
      // console.log('选择', val)
      this.multipleSelection = val
    },
    computedPricePointNum(row) {
      let pricePointNum = 6
      for (let i = 0; i < this.commodityParamListGetter.length; i++) {
        const element = this.commodityParamListGetter[i]
        if (row.commodityId == element.commodityId) {
          pricePointNum = element.priceScale
        }
      }
      return pricePointNum
    },
    getIndexPage() {
      if (this.page) {
        this.pageData.pageSize = Number(this.page)
      }
    },
    // 分页
    handleSizeChange(val) {
      this.pageData.pageSize = val
      this.showData()
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val
      this.showData()
    },
    orderPage() {
      protocol.queryUserEntrusts.data.firmId = this.sessionInfoGetter.firmId
      protocol.queryUserEntrusts.data.page = this.pageData.currentPage - 1
      protocol.queryUserEntrusts.data.size = '' + this.pageData.pageSize
      http.gPost(protocol.queryUserEntrusts).then(response => {
        if (response.data) {
          if (response.data && response.data.code == '0') {
            const arr = response.data.value.content
            this.judgeDataType(arr)
            this.OrderFrontSum = response.data.value.sumData
          }
        }
      })
    },
    // 订单展示
    showData() {
      const that = this
    //   protocol.queryUserEntrusts.data.firmId = this.sessionInfoGetter.firmId
      protocol.queryUserEntrusts.param.firmId = 0
      protocol.queryUserEntrusts.param.commodityId = this.commodityIds
      protocol.queryUserEntrusts.param.page = 0
      protocol.queryUserEntrusts.param.size = 9999
      http.postFrontGX(protocol.queryUserEntrusts).then(response => {
        if (response.data) {
          if (response.data && response.data.code == '0') {
              console.log('元数据', response.data.value.content)
            this.pageData.total = response.data.value.totalElements
            const arr = response.data.value.content
            this.judgeDataType(arr)
            this.OrderFrontSum = response.data.value.sumData
          }
        }
      })

      // protocol.queryOrderFrontSum.data = protocol.queryUserEntrusts.data
      // http.gPost(protocol.queryOrderFrontSum).then(response => {
      //   if (response.data.code != 0) {
      //     this.$EL_MESSAGE({ message: response.data.message })
      //   }
      //   if (response.data) {
      //     if (response.data && response.data.code == '0') {
      //       this.OrderFrontSum = response.data.value
      //     }
      //   }
      // })
    },
    // 判断数据类型
    judgeDataType(arr) {
        const that = this
        console.log(arr, '初始数据')
      this.each(arr, function(arrIndex) {
          console.log('传入数据', arrIndex)
        // 判断时间
        that.modifyTime(arrIndex, 'createTime')
        // 判断方向
        that.modifyData(arrIndex, 'bsFlag', 'B', that.$t('message.Buy'), that.$t('message.Sell'))
        that.modifyData(arrIndex, 'ocFlag', 'O', '订立', '转让')
        // 判断委托状态 1-已委托，2-已成交，3-已撤单，4-部分成交，5-部分成交后撤单
        const stateList = ['已委托', '已成交', '已撤单', '部分成交', '部分成交后撤单']
        stateList.forEach((item, index) => {
          that.modifyData(arrIndex, 'orderStatus', index + 1, item)
        })
        // 价格
        arrIndex.allPrice = (Number(arrIndex.quantity) * Number(arrIndex.singlePrice) * that.selectCommodityStrategy(arrIndex.commodityId).unitNumeral).toFixed(that.coinSmallDigital(that.selectCommodityStrategy(arrIndex.commodityId)))
      })
      const nutableArr = this.multipleSelection
      this.tableData = arr
      this.memberDatas = arr
      console.log(this.memberDatas, '最终数据')
      const newTable = []
      for (const tableCell of nutableArr) {
        for (const newCell of arr) {
          if (tableCell.orderId === newCell.orderId) {
            newTable.push(newCell)
          }
        }
      }
      setTimeout(() => {
        that.toggleSelection(newTable)
      }, 0)
    },
    // 更改选中
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.table.toggleRowSelection(row)
        })
      } else {
        this.$refs.table.clearSelection()
      }
    },
    // 撤单
    revoke(obj) {
      this.dialogVisible = true
      this.sOrderId = obj.orderId
    },
    revokeConfirm() {
      // // console.log(this.sOrderId);
      // const that = this
      protocol.withdrawLimitOrder.data.orderId = this.sOrderId
      http.gPost(protocol.withdrawLimitOrder).then(response => {
        if (response.data) {
          this.$EL_MESSAGE(response.data.message)
          if (response.data && response.data.code == '0') {
            this.showData()
          }
        }
      })
      this.dialogVisible = false
    },
    // 排序
    sortChange(column, prop, order) {
      if (column.order == 'ascending') {
        protocol.queryUserEntrusts.data.sort[0].direction = 'ASC'
      } else {
        protocol.queryUserEntrusts.data.sort[0].direction = 'DESC'
      }
      protocol.queryUserEntrusts.data.firmId = this.sessionInfoGetter.firmId
      if (column.column) {
        this.eachSortChange(column.column.property)
        http.gPost(protocol.queryUserEntrusts).then(response => {
          if (response.data.code != 0) {
            this.isNetWrong = true
            this.$EL_MESSAGE({ message: response.data.message })
          } else {
            this.isNetWrong = false
          }
          if (response.data) {
            if (response.data && response.data.code == '0') {
              this.pageData.total = response.data.value.totalElements
              const arr = response.data.value.content
              console.log(arr, 'arr1')
              this.judgeDataType(arr)
              this.OrderFrontSum = response.data.value.sumData
            }
          }
        })
      }
    },
    // 分项排序
    eachSortChange(str) {
      return (protocol.queryUserEntrusts.data.sort[0].property = str)
    },
    // 单元格颜色
    directionClass(row, column, rowIndex, columnIndex) {
      // 根据字段改变样式
      switch (row.columnIndex) {
        // eslint-disable-next-line no-lone-blocks
        case 3: {
          return this.setStrClass(row.row.bsFlag, this.$t('message.Sell'), 'dropColor', 'increaseColor')
          // if (row.row.OCBSType == this.$t('message.O_L') || row.row.OCBSType == this.$t('message.C_L')) {
          //   return 'increaseColor'
          // } else {
          //   return 'dropColor'
          // }
        }
          // eslint-disable-next-line no-unreachable
          break
        default:
          break
      }
    },
    toThousands(num) {
      if (typeof num === 'number' && !isNaN(num)) {
        var result = ''
        num = Number(num.toString() || 0).toFixed(2)
        var numAry = num.toString().split('.')
        var preNum = numAry[0]
        var lastNum = numAry[1]
        while (preNum.length > 3) {
          result = ',' + preNum.slice(-3) + result
          preNum = preNum.slice(0, preNum.length - 3)
        }
        if (preNum) {
          result = preNum + result
        }
        result = result + '.' + lastNum
        return result
      } else {
        return num
      }
    },
    // 合计
    getSummaries(param) {
      const { columns } = param
      // console.log(columns, data)
      const sums = []
      const that = this
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计'
          return
        }
        // if(index === 5){//浮动盈亏
        //   sums[index] = that.OrderFrontSum.floatPL.toFixed(coinSmallDigital(selectCommodityStrategy(scope.row.commodityId)).coinDigitalNum)
        // }
        if (index === 6) { // 委托数量
          sums[index] = that.OrderFrontSum.quantity
          return
        }
        if (index === 8) { // 冻结手续费
          sums[index] = that.toThousands(Number(that.OrderFrontSum.frozeFee))
          return
        }
        if (index === 9) { // 冻结履约订金
          sums[index] = that.toThousands(Number(that.OrderFrontSum.frozeMargin))
          return
        }
        if (index === 11) {
          // 未成交数量
          sums[index] = that.OrderFrontSum.unDealQuantity
          return
        }
        sums[index] = ''
      })
      return sums
    },
    // 根据语言改变样式
    setLanguageCss() {
      // 防止清缓存后数据消失
      this.$i18n.locale = localStorage.lang || navigator.language.slice(-2).toLowerCase()
      switch (localStorage.lang) {
        case 'en': {
          const tetqTableEnWidth = {
            bsFlag: 120,
            orderPrice: 120,
            orderQuantity: 120,
            ocFlag: 150,
            orderType: 150,
            State: 100,
            stopLossPrice: 120
          }
          localStorage.setItem('tetqTableEnWidth', JSON.stringify(tetqTableEnWidth))
          this.tetqTableWidth = JSON.parse(localStorage.tetqTableEnWidth)
        }
          break
        default:
          break
      }
    }
  }
}
</script>
<style>
.cell {
    text-align: center!important;
}
#TradeEntrustTheQuery {
    width: 100%;
}
#TradeEntrustTheQuery .el-table__footer-wrapper tbody td,#TradeEntrustTheQuery .el-table__header-wrapper tbody td {
    /* background-color: #131625; */
  }
#TradeEntrustTheQuery .el-table__body-wrapper{
  height: 200px !important;
}
</style>
<style scoped lang="scss">
/deep/ #btn1 .el-button .el-button--text span {
  color: #2483FF!important;
}
.seamless-warp {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .title {
      display: flex;
      margin-bottom: 1vw;
      align-items: center;
      .t_item {
          width: 16%;
          text-align: center;
      }
  }
  .content {
      display: flex;
      align-items: center;
        height: 2.5vw;
      .c_item {
        width: 16%;
        text-align: center;
      }
  }
</style>
